export const innerGrade = {
    Yellow: {
      "Fancy Vivid": [
        {
          image: "vivid-inner-1.png",
          text: "Represents the point of transition from ‘Intense’ to ‘Vivid’.",
          bg: "#FFEE82",
        },
        {
          image: "vivid-inner-2.png",
          text: "Represents a moderate Vivid grade, with over 70% of vivid yellows falling into this category.",
          bg: "#FFEB6B",
        },
        {
          image: "vivid-inner-3.png",
          text: "Represents a solid vivid grade.",
          bg: "#FFE858",
        },
        {
          image: "vivid-inner-4.png",
          text: "Represents a very strong vivid grade.",
          bg: "#FBDC19",
        },
        {
          image: "vivid-inner-5.png",
          text: "Represents diamonds with an exceptional saturation rarely seen (as in some known as Zimmi or from the Misery pipe in Canada).",
          bg: "#EECF09",
        },
      ],
      "Fancy Intense": [
        {
          image: "intense-inner-1.png",
          text: "Represents the point of transition from fancy to intense.",
          bg: "#FFEF8A",
        },
        {
          image: "intense-inner-2.png",
          text: "Represents a moderate intense grade, with over 80% of intense yellows falling into this category.",
          bg: "#FBEA81",
        },
        {
          image: "intense-inner-3.png",
          text: "Represents a solid Intense grade.",
          bg: "#FDE969",
        },
        {
          image: "intense-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between intense and vivid.",
          bg: "#FFEB6B",
        },
      ],
      Fancy: [
        {
          image: "fancy-inner-1.png",
          text: "Represents the point of transition from “Fancy Light” to “Fancy”.",
          bg: "#FFF29F",
        },
        {
          image: "fancy-inner-2.png",
          text: "Represents a moderate “Fancy” grade, with over 80% of “Fancy” yellows falling into this category.",
          bg: "#FFF196",
        },
        {
          image: "fancy-inner-3.png",
          text: "Represents a solid “Fancy” grade.",
          bg: "#FFF093",
        },
        {
          image: "fancy-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy” and “Intense”.",
          bg: "#FFEE86",
        },
      ],
      "Fancy Light": [
        {
          image: "fancy-light-inner-1.png",
          text: "Represents the point of transition from “Y-Z” to “fancy light”.",
          bg: "#FFF4AE",
        },
        {
          image: "fancy-light-inner-2.png",
          text: "Represents a moderate “Fancy Light” grade, with over 85% of “Fancy Light” diamonds falling into this category.",
          bg: "#FFF3AA",
        },
        {
          image: "fancy-light-inner-3.png",
          text: "Represents a solid “Fancy Light” grade.",
          bg: "#FFF29E",
        },
        {
          image: "fancy-light-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy Light” and “Fancy”.",
          bg: "#FEF2A3",
        },
      ],
      "Fancy Deep": [
        {
          image: "deep-inner-4.png",
          text: "Represents a solid “Fancy Deep” grade.",
          bg: "#FFF4AE",
        },
      ],
    },

    Pink: {
      "Fancy Vivid": [
        {
          image: "vivid-inner-1.png",
          text: "Represents the point of transition from ‘Intense’ to ‘Vivid’.",
          bg: "#DA8CB0",
        },
        {
          image: "vivid-inner-2.png",
          text: "Represents a moderate Vivid grade, with over 70% of vivid pinks falling into this category.",
          bg: "#BB6E92",
        },
        {
          image: "vivid-inner-3.png",
          text: "Represents a solid vivid grade.",
          bg: "#A84B80",
        },
        {
          image: "vivid-inner-4.png",
          text: "Represents a very strong vivid grade.",
          bg: "#91406E",
        },
        {
          image: "vivid-inner-5.png",
          text: "Represents diamonds with an exceptional saturation rarely seen (as in some known as Zimmi or from the Misery pipe in Canada).",
          bg: "#833D57",
        },
      ],
      "Fancy Intense": [
        {
          image: "intense-inner-1.png",
          text: "Represents the point of transition from fancy to intense.",
          bg: "#F2A0C6",
        },
        {
          image: "intense-inner-2.png",
          text: "Represents a moderate intense grade, with over 80% of intense pinks falling into this category.",
          bg: "#D375A0",
        },
        {
          image: "intense-inner-3.png",
          text: "Represents a solid Intense grade.",
          bg: "#C95396",
        },
        {
          image: "intense-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between intense and vivid.",
          bg: "#AD4680",
        },
      ],
      Fancy: [
        {
          image: "fancy-inner-1.png",
          text: "Represents the point of transition from “Fancy Light” to “Fancy”.",
          bg: "#F2CADB",
        },
        {
          image: "fancy-inner-2.png",
          text: "Represents a moderate “Fancy” grade, with over 80% of “Fancy” pinks falling into this category.",
          bg: "#F2BFD5",
        },
        {
          image: "fancy-inner-3.png",
          text: "Represents a solid “Fancy” grade.",
          bg: "#E0A7BF",
        },
        {
          image: "fancy-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy” and “Intense”.",
          bg: "#D8A1B8",
        },
      ],
      "Fancy Light": [
        {
          image: "fancy-light-inner-1.png",
          text: "Represents the point of transition from “Y-Z” to “fancy light”.",
          bg: "#FCDEEB",
        },
        {
          image: "fancy-light-inner-2.png",
          text: "Represents a moderate “Fancy Light” grade, with over 85% of “Fancy Light” diamonds falling into this category.",
          bg: "#F5CFDF",
        },
        {
          image: "fancy-light-inner-3.png",
          text: "Represents a solid “Fancy Light” grade.",
          bg: "#E9BCCF",
        },
        {
          image: "fancy-light-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy Light” and “Fancy”.",
          bg: "#DCAABF",
        },
      ],
      "Fancy Deep": [
        {
          image: "deep-inner-4.png",
          text: "Represents a solid “Fancy Deep” grade.",
          bg: "#DCAABF",
        },
      ],
    },
    Blue: {
      "Fancy Vivid": [
        {
          image: "vivid-inner-1.png",
          text: "Represents the point of transition from ‘Intense’ to ‘Vivid’.",
          bg: "#299AEC",
        },
        {
          image: "vivid-inner-2.png",
          text: "Represents a moderate Vivid grade, with over 70% of vivid blues falling into this category.",
          bg: "#057FD7",
        },
        {
          image: "vivid-inner-3.png",
          text: "Represents a solid vivid grade.",
          bg: "#036EBA",
        },
        {
          image: "vivid-inner-4.png",
          text: "Represents a very strong vivid grade.",
          bg: "#005AA1",
        },
      ],
      "Fancy Intense": [
        {
          image: "intense-inner-1.png",
          text: "Represents the point of transition from fancy to intense.",
          bg: "#5FB4F0",
        },
        {
          image: "intense-inner-2.png",
          text: "Represents a moderate intense grade, with over 80% of intense blues falling into this category.",
          bg: "#4BA2E0",
        },
        {
          image: "intense-inner-3.png",
          text: "Represents a solid Intense grade.",
          bg: "#3D89C0",
        },
        {
          image: "intense-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between intense and vivid.",
          bg: "#2577AF",
        },
      ],
      Fancy: [
        {
          image: "fancy-inner-1.png",
          text: "Represents the point of transition from “Fancy Light” to “Fancy”.",
          bg: "#94CEF3",
        },
        {
          image: "fancy-inner-2.png",
          text: "Represents a moderate “Fancy” grade, with over 80% of “Fancy” blues falling into this category.",
          bg: "#85BEE3",
        },
        {
          image: "fancy-inner-3.png",
          text: "Represents a solid “Fancy” grade.",
          bg: "#75AACC",
        },
        {
          image: "fancy-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy” and “Intense”.",
          bg: "#5A95C1",
        },
      ],
      "Fancy Light": [
        {
          image: "fancy-light-inner-1.png",
          text: "Represents the point of transition from “Y-Z” to “fancy light”.",
          bg: "#94CEF3",
        },
        {
          image: "fancy-light-inner-2.png",
          text: "Represents a moderate “Fancy Light” grade, with over 85% of “Fancy Light” diamonds falling into this category.",
          bg: "#85BEE3",
        },
        {
          image: "fancy-light-inner-3.png",
          text: "Represents a solid “Fancy Light” grade.",
          bg: "#75AACC",
        },
        {
          image: "fancy-light-inner-4.png",
          text: "Represents a very strong saturation - the point of transition between “Fancy Light” and “Fancy”.",
          bg: "#5A95C1",
        },
      ],
      "Fancy Deep": [
        {
          image: "deep-inner-4.png",
          text: "Represents a solid “Fancy Deep” grade.",
          bg: "#5A95C1",
        },
      ],
    },
  };

 export const dispersionTexts = [
    "When the face-up view exhibits approximately 60%-95% colorless areas, typically seen in old-fashioned, unmodified colorless diamonds.",
    "When the face-up view exhibits approximately 40%-60% colorless areas.",
    "When the face-up view exhibits approximately 20%-35% colorless patches.",
    "When the face-up view exhibits approximately 10%-15% colorless patches.",
    "When the face-up view exhibits less than 5% colorless areas.",
  ];

export  const undertone_data = {
    Yellow: [
      {
        image: "1-1",
        value: "1",
        text: "When a prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier.",
        bg: "#D1B710",
      },
      {
        image: "2-1",
        value: "2",
        text: "When a noticeable green or brown color sensation is visible, moderately impacting the yellow appearance.",
        bg: "#E4C608",
      },
      {
        image: "3-1",
        value: "3",
        text: "When an insignificant undertone is present—over ~70% of diamonds fall into this category.",
        bg: "#F9DB1F",
      },
      {
        image: "4",
        value: "4",
        text: "When the yellow hue exhibits an absoluty yellow color—less than ~2% of yellow diamonds qualify for this grade (those with a delicate bright orange hue may also be included).",
        bg: "#FFF348",
      },
      {
        image: "3-2",
        value: "3",
        text: "When an insignificant undertone is present—over ~70% of diamonds fall into this category.",
        bg: "#F5F23D",
      },
      {
        image: "2-2",
        value: "2",
        text: "When a noticeable green or brown color sensation is visible, moderately impacting the yellow appearance.",
        bg: "#F0ED0E",
      },
      {
        image: "1-2",
        value: "1",
        text: "When a prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier.",
        bg: "#E8E40A",
      },
    ],

    Pink: [
      {
        image: "1",
        value: "1",
        text: "Exhibiting a strong presence of brown, orange, gray, or a muted sensation influence.",
        bg: "#E193A0",
      },
      {
        image: "2",
        value: "1",
        text: "Displaying a moderate presence of brown, orange, gray, or a muted sensation.",
        bg: "#D58897",
      },
      {
        image: "3",
        value: "3",
        text: "Reaching the threshold of a standard pink hue.",
        bg: "#CF81B1",
      },
      {
        image: "4",
        value: "4",
        text: "Exhibiting a highly pleasing pink sensation.",
        bg: "#E38FB0",
      },
      {
        image: "5",
        value: "4+",
        text: "Exhibiting an exceptionally vibrant pink sensation.",
        bg: "#E97BB4",
      },
    ],
    Blue: [
      {
        image: "1",
        value: "1",
        text: "When a prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier.",
        bg: "#A9B3BC",
      },
      {
        image: "2",
        value: "1",
        text: "When a noticeable green or brown color sensation is visible, moderately impacting the blue appearance.",
        bg: "#8EA9C1",
      },
      {
        image: "3",
        value: "3",
        text: "When an insignificant undertone is present—over ~70% of diamonds fall into this category.",
        bg: "#72A2CD",
      },
      {
        image: "4",
        value: "4",
        text: "When the blue hue exhibits an absoluty blue color—less than ~2% of blue diamonds qualify for this grade (those with a delicate bright orange hue may also be included).",
        bg: "#509DE1",
      },
    ],
    "Yellow Fancy Deep": [
      {
        image: "1_2",
        value: "1/2",
        text: "Lorem ipsum 1/2 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",
        bg: "#C7B848",
      },
      {
        image: "3_4",
        value: "3/4",
        text: "Lorem ipsum 3/4 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",
        bg: "#F5F13D",
      },
    ],
    "Pink Fancy Deep": [
      {
        image: "1_2",
        value: "1/2",
        text: "Lorem ipsum 1/2 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",

        bg: "#E193A0",
      },
      {
        image: "3_4",
        value: "3/4",
        text: "Lorem ipsum 3/4 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",
        bg: "#E97BB4",
      },
    ],
    "Blue Fancy Deep": [
      {
        image: "1_2",
        value: "1/2",
        text: "Lorem ipsum 1/2 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",
        bg: "#8EA9C1",
      },
      {
        image: "3_4",
        value: "3/4",
        text: "Lorem ipsum 3/4 prominent green or brown color sensation is visible, which may mistakenly appear as a secondary modifier",

        bg: "#72A2CD",
      },
    ],
  };