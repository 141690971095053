import { PRICE_INTESITY_ERROR_MSG, SUPPORT_COLOR_ERROR_MSG, WEIGHT_LIMIT_ERROR_MSG } from './const'
import { diamondColors, diamondSaturations, diamondShapes, diamondPolish,clarity_T_Value } from "./diamondData";

export const handleInfoModal = (
  infoModal,
  setInfoModal,
  infoData,
  setInfoData
) => {
  setInfoModal(!infoModal);
  setInfoData(infoData);
};
export const checkGIAReport = (data, toast,  setErrMsg)=>{
  let {carat_weight, saturation, color, shape, clarity,  ratio, fluorescence, polish} = data || {};
  if(!carat_weight || (parseFloat(carat_weight) < 1 || parseFloat(carat_weight) > 30)){
    setErrMsg(WEIGHT_LIMIT_ERROR_MSG);
    return {
      status: false,
      msg: WEIGHT_LIMIT_ERROR_MSG
    }
  }
  if(!saturation || diamondSaturations?.findIndex((el)=> el === saturation) < 0){
    setErrMsg(PRICE_INTESITY_ERROR_MSG(saturation,'Intensity'));
    return {
      status: false,
      msg: PRICE_INTESITY_ERROR_MSG(saturation,'Intensity')
    }
  }

  if(!color || diamondColors.findIndex((element)=>element.value === color) < 0){
    setErrMsg(SUPPORT_COLOR_ERROR_MSG);
    return {
      status: false,
      msg: SUPPORT_COLOR_ERROR_MSG
    }
  }

  if(!shape || diamondShapes?.findIndex((el)=> el === shape) < 0){
    setErrMsg(PRICE_INTESITY_ERROR_MSG(shape,'Shape'));
    return {
      status: false,
      msg: PRICE_INTESITY_ERROR_MSG(shape,'Shape')
    }
  }
  if(!clarity || !clarity_T_Value?.includes(clarity)){
    setErrMsg(PRICE_INTESITY_ERROR_MSG(clarity,'Clarity'));
    return {
      status: false,
      msg: PRICE_INTESITY_ERROR_MSG(clarity,'Clarity')
    }
  }

  if(!ratio || ((Number(ratio)< 0.70 && Number(ratio)> 2.00 ) && shape !=='Round')){
      setErrMsg(PRICE_INTESITY_ERROR_MSG(ratio,'Ratio'));
      return {
        status: false,
        msg: PRICE_INTESITY_ERROR_MSG(ratio,'Ratio')
      }
  }

  if(!fluorescence || fluorescence?.toLowerCase().includes('white')){
    setErrMsg(PRICE_INTESITY_ERROR_MSG(fluorescence,'Fluorescence'));
    return {
      status: false,
      msg: PRICE_INTESITY_ERROR_MSG(fluorescence,'Fluorescence')
    }
    }

  if(!polish || diamondPolish?.findIndex((element)=>element.code.toLowerCase() === polish.toLowerCase()) < 0){
    setErrMsg(PRICE_INTESITY_ERROR_MSG(polish,'Polish'));
    return {
      status: false,
      msg: PRICE_INTESITY_ERROR_MSG(polish,'Polish')
    }
  }

  return {
    status: true,
    msg: ''
  }
}

 const formatString = (word)=> {
  if (word?.toLowerCase() ==='excellent') {
    return 'Ex';
  } else {
    const splitWord = word?.split(' ');
    if(splitWord.length ===2){
      return `${splitWord[0]?.charAt(0)?.toUpperCase()}${splitWord[1]?.charAt(0)?.toLowerCase()}`
    }else{
      return `${splitWord[0]?.charAt(0)?.toUpperCase()}`
    }
  }
}

export const getPolishAndSymmetryValue = (polish,symmetry)=>{
  if(polish?.includes('Fair') || symmetry?.includes('Fair')){
    return 'Fair';
  }
  if(polish?.includes('Poor') || symmetry?.includes('Poor')){
    return 'Poor';
  }
  if(polish?.length <=2 && symmetry?.length <=2){
    return `${polish},${symmetry}`;
  }
  const formattedString1 = formatString(polish);
  const formattedString2 = formatString(symmetry);
  //const result = `${formattedString2},${formattedString1}`;
  const result = `${formattedString1},${formattedString2}`;
  return result
}

const commaFormatter = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateThreePercentLess = (givenNumber ,checkAssets  ) => {
let minPrice = 0
let maxPrice = 0
  if(checkAssets){
    minPrice = givenNumber * 0.95
    maxPrice = givenNumber * 1.05
  }else{
    minPrice = givenNumber * 0.97
    maxPrice = givenNumber * 1.03
  }
  if (minPrice > 0 && minPrice < 10000){
    minPrice = parseInt(minPrice / 50) * 50 + 50
  }else if (minPrice > 10000 && minPrice <1000000){
    minPrice = parseInt(minPrice / 500) * 500 + 500
  }else if (minPrice >= 1000000){
    minPrice = parseInt(minPrice / 5000) * 5000 + 5000
  }

  if (maxPrice > 0 && maxPrice < 10000){
    maxPrice = parseInt(maxPrice / 50) * 50 + 50
  }else if (maxPrice >= 10000 && maxPrice <1000000){
    maxPrice = parseInt(maxPrice / 500) * 500 + 500
  }else if (maxPrice >= 1000000){
    maxPrice = parseInt(maxPrice / 5000) * 5000 + 5000
  }
  return `$${commaFormatter(Math.round(minPrice))}-$${commaFormatter(Math.round(maxPrice))}`;
  
};

export const isShapeRound =(shape)=>{
  if(shape==='Round') return true;
  return false
}

export const getOpacityValue = (saturation) => {
  if (saturation === "Fancy Vivid") {
    return 1;
  } else if (saturation === "Fancy Intense") {
    return 0.8;
  } else if (saturation === "Fancy") {
    return 0.7;
  } else if (saturation === "Fancy Light") {
    return 0.6;
  }
  return 1;
}

export const subsequentBlankField = (pricestepData) => {
  if (!pricestepData?.shape) {
    return "shape";
  }else if (!pricestepData?.color) {
    return "color";
  }else if(!pricestepData?.saturation){
    return "saturation";
  }else if(!pricestepData?.clarity){
    return "clarity";
  }else if(!pricestepData?.fluorescence){
    return "fluorescence";
  }else if(!pricestepData?.ratio){
    return "ratio";
  }else if(!pricestepData?.polish){
    return "polish";
  }else if(!pricestepData?.symmetry){
    return "symmetry";
  }else {
    
  }
  
}