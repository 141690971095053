import { useContext, useEffect, useRef, useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import backArrow from "../../assets/images/pricing/back-arrow.svg";
import BackModal from "../backModal/backModal";
import Sidebar from "../sidebar";
import barIcon from "../../assets/images/pricing/menu-icon.svg";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuicon from "../../assets/images/pricing/menuIcon.svg";
import searchHistory from "../../assets/images/pricing/search-icon.svg";
import creditIcon from "../../assets/images/pricing/credit.svg";
import { PriceContext } from "../../context";

const Header = ({ title, loader }) => {
  const location = useLocation();
  const navigate = useNavigate();
    const getContext = useContext(PriceContext);
    const {piCredits } = getContext;
  const expand = false;
  const [backModal, setBackModal] = useState(false);
  const [show, setShow] = useState(false);
  const [barMenuOpen, setBarMenuOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const menuRef = useRef(null);

  const handleBackModal = () => {
    if (location.pathname === "/price-intelligence-search-history") {
      navigate(-1);
    } else {
      setBackModal(!backModal);
    }
  };
  const handleMenubar = () => {
    setBarMenuOpen(!barMenuOpen);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setBarMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {title === "new-header" ? (
        <div
          className={`container1 new-head ${loader ? 'd-none': ''} ${
            location.pathname === "/result" 
              ? "result-header"
              : location.pathname === "/pricing"
              ? "pricing-header"
              : ""
          }`}
        >
          <div
            className="d-flex align-items-center pt-3 pb-0 "
            role="button"
            onClick={handleBackModal}
          >
            <Button className="back-contain-btn">
              <img src={backArrow} alt="icon" />
            </Button>
            <p className="p-0 m-0 back-text">Back</p>
          </div>
          {location.pathname === "/result" && (
            <>
              <Button
                className="start-pricing-menuIcon"
                onClick={handleMenubar}
              >
                <img src={menuicon} alt="menu" />
              </Button>
              {barMenuOpen && (
                <div className="start-menu-pricing" ref={menuRef}>
                  <div className="start-menu-pricing-data">
                    <Link
                      to="/price-intelligence-search-history"
                      className="start-menu-link"
                    >
                      <img src={searchHistory} alt="search-history" />{" "}
                      <p>Search History</p>
                    </Link>
                  </div>
                  <div className="start-menu-pricing-data">
                    <Link className="start-menu-link">
                      <img src={creditIcon} alt="search-history" />{" "}
                      <p>
                        Credits:{" "}
                        {piCredits === "-1" || piCredits === -1
                          ? "Unlimited"
                          : piCredits}
                      </p>
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}

          <BackModal backModal={backModal} handleBackModal={handleBackModal} />
        </div>
      ) : (
        <div className="header">
          <Navbar key={expand} expand={expand}>
            <div className="header-back">
              <Button onClick={handleBackModal}>
                <img src={backArrow} alt="icon" />
              </Button>
              <p>{title ? "Back" : "Pricing"}</p>
            </div>
            <Button className="menu-toggle" onClick={handleShow}>
              <img src={barIcon} alt="menu" />
            </Button>
            <Sidebar show={show} handleClose={handleClose} />
          </Navbar>
          <BackModal backModal={backModal} handleBackModal={handleBackModal} />
        </div>
      )}
    </>
  );
};

export default Header;
