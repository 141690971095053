/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import PriceIntelligenceLayout from "..";
import Header from "../header/header";
import PricingSlider from "./slider";
import { PriceContext } from "../../context";
import "./pricing.css";
import WarningModal from "../warningModal/WarningModal";
import Loader from "../loader/loader";

const Pricing = ({action, priceData}) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData , setReportType } = getContext;
  const [loading, setLoading] = useState(false);
  const[warningModaldata, setwarningModaldata]=useState(false)
  
  useEffect(() => {
    if (action === "fromstartmanualpricing") {
      setReportType("byManual");
    }else{
      setReportType("byGia");
      setPricestepData(priceData);
      
    }


  
  }, [action]);
 const handleWarningModal=()=>{
  setwarningModaldata(!warningModaldata)
}

  useEffect(() => {
    document.addEventListener("keydown", stopTabFunction);
    return () => {
      document.removeEventListener("keydown", stopTabFunction);
    };
  }, []);

  const stopTabFunction = function (e) {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
  };

  return (
    <PriceIntelligenceLayout>
      <>
        {loading && <Loader/>}
        <Header title="new-header"/>
      
        <div className="pricing-page">
          <PricingSlider setLoading={setLoading} setwarningModal={setwarningModaldata}/>
          
        </div>
       
        <WarningModal warningModaldata={warningModaldata}  handleWarningModal={handleWarningModal}  text={"contact support to purchase more credits"}/>
      </>
    </PriceIntelligenceLayout>
  );
};

export default Pricing;
