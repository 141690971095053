import { useState, useEffect } from "react";
import "./pricing.css";

const DesktopPairType = ({
  pricestepData,
  setPricestepData,
  setDisableSelect,
  from,
}) => {
  const [activeTab, setActiveTab] = useState(false);
  const [activeTabName, setActiveTabName] = useState("single");
  const handleTab = (name) => {

    // if (
    //   !pricestepData?.colorUndertone
    // ) {
    //   if (from === "desktop") {
    //     setDisableSelect(true);
    //   }
    //   return;
    // }

    setActiveTab(!activeTab);
    const text = activeTab ? "single" : "pair";

    setActiveTabName(text);
    setPricestepData({ ...pricestepData, singlePair: text });
  };

  useEffect(() => {
    if (pricestepData?.singlePair === "pair") {
      handleTab("pair");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-btns-wrapper">
      <input
        className="single-pair-check"
        type="checkbox"
        id="checkbox_toggle"
        checked={activeTab}
      />
      <div
        className={`single-pair-checkbox ${
          !pricestepData?.singlePair ? "disable_pair_border" : ""
        }`}
      >
        <label htmlFor="checkbox_toggle" className="single-pair-slide">
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-toggle  ${
              !pricestepData?.singlePair ? "pair-disabled" : ""
            }`}
          ></label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "single" ? " single" : ""
            }`}
            onClick={() => handleTab("single")}
          >
            single
          </label>
          <label
            htmlFor="checkbox_toggle"
            className={`single-pair-text ${
              activeTabName === "pair" ? "pair" : ""
            }`}
            onClick={() => handleTab("pair")}
          >
            pair
          </label>
        </label>
      </div>
    </div>
  );
};

export default DesktopPairType;
