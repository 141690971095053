import React from 'react'

const DisableMobile = () => {
  return (
    <div className='disable-mobile'>
     <h3>The Price Intelligence is available in desktop version only</h3> 
    </div>
  )
}

export default DisableMobile
