// import { useEffect, useMemo, useState } from "react";
import { undertone_data } from "../../utils/piIduData";
import { clientPath } from "../../config/key";
import { getOpacityValue } from "../../utils/function";
import { useState } from "react";

const UndertoneComponent = ({
  pricestepData,
  setPricestepData,
  getValueByField,
}) => {
  
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <div>
      <div className="idu-section-container">
        <div className="idu-top-section">
          <h5>undertone</h5>
          <p
            style={{
              lineHeight:
                pricestepData?.color === "Yellow" &&
                pricestepData?.saturation !== "Fancy Deep"
                  ? "19px"
                  : "22px",
            }}
          >
            {pricestepData?.color === "Yellow" &&
            pricestepData?.saturation !== "Fancy Deep"
              ? `Measures the deviation from the pure yellow hue,
          ranging from a strong green sensation to a brown
          influence.`
              : "The amount of Gray sensation influencing blue diamonds."}{" "}
            <span onClick={() => getValueByField("color Undertone")}>
              Read more…
            </span>
          </p>
        </div>
        <div className="idu-main-section">
          <div className="idu-undertone-arrow-section">
            {pricestepData?.color === "Yellow" &&
              pricestepData?.saturation !== "Fancy Deep" && (
                <>
                  <div className="undertone-arrow">
                    <p>WARM</p>
                    <img
                      src={`${clientPath}/icons/undertone-arrow-left.png`}
                      alt=""
                    />
                  </div>
                  <div className="undertone-arrow-text">
                    <p>primary yellow</p>
                  </div>
                  <div className="undertone-arrow">
                    <p>COLD</p>
                    <img
                      src={`${clientPath}/icons/undertone-arrow-right.png`}
                      alt=""
                    />
                  </div>
                </>
              )}
          </div>

          <div className="idu-image-box">
            {undertone_data[
              pricestepData?.saturation === "Fancy Deep"
                ? pricestepData?.color + " Fancy Deep"
                : pricestepData?.color
            ]?.map((item, index) => {
              return (
                <div className="idu-image-container">
                  <div
                    className={`idu-image ${
                      (pricestepData?.colorUndertone === "4+"
                        ? "5"
                        : pricestepData?.colorUndertone) === `${index + 1}`
                        ? "active-image"
                        : ""
                    }`}
                    key={index}
                    onClick={() => {
                      setPricestepData({
                        ...pricestepData,
                        colorUndertone: `${index + 1}`,
                      });
                    }}
                    onMouseEnter={() => {
                      setHoverIndex(index)
                     }}
                     onMouseLeave={() => {
                      setHoverIndex(null)
                     }}
                  >
                    <img
                      src={`${clientPath}/pi-idu-images/${pricestepData?.color?.toLowerCase()}/${pricestepData?.color?.toLowerCase()}${
                        pricestepData?.saturation === "Fancy Deep"
                          ? "-deep"
                          : ""
                      }-undertone-${item?.image}.png`}
                      alt=""
                      style={{
                        opacity: getOpacityValue(pricestepData?.saturation),
                      }}
                    />
                  </div>
                  <p
                    style={{
                      background: item?.bg,
                      color:
                        pricestepData?.color === "Yellow" ? "#5c6da4" : "#ffff",
                    }}
                  >
                    {item?.value}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="idu-text-box">
            <p>
              {
                undertone_data[
                  pricestepData?.saturation === "Fancy Deep"
                    ? pricestepData?.color + " Fancy Deep"
                    : pricestepData?.color
                ][hoverIndex?? (pricestepData?.colorUndertone - 1)]?.text
              }
            </p>
          </div>
        </div>
        <div className="idu-bottom-section">
          {Number(
            pricestepData?.colorUndertone === "4+"
              ? 5
              : pricestepData?.colorUndertone
          ) > 2 &&
            Number(
              pricestepData?.colorUndertone === "4+"
                ? 5
                : pricestepData?.colorUndertone
            ) < 6 && (
              <h6>
                {" "}
                <img
                  src={`${clientPath}/icons/idu_info.svg`}
                  height={"21px"}
                  width={"21px"}
                  alt="alert"
                />{" "} 
                Overvaluing Visual Features Leads to Wrong Pricing!
              </h6>
            )}
        </div>
      </div>
    </div>
  );
};

export default UndertoneComponent;
