import React, { useContext, useEffect, useState } from "react";
import Header from "../header/header";
import "./search.css";
import DelIcon from "../../assets/images/pricing/delIcon.svg";
import { useNavigate } from "react-router-dom";
import { priceIntelligenceInstance } from "../../config/axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { calculateThreePercentLess } from "../../utils/function";
import { UAParser } from "ua-parser-js";
import { PriceContext } from "../../context";
import { clientPath } from "../../config/key";
import Footer from "../footer/footer";
import * as animationData from "../diamondLoader/Diamondloader3d.json";
import Lottie from "react-lottie";

const Searchhistory = () => {
  const navigate = useNavigate();
  const getContext = useContext(PriceContext);
  const { setPricestepData, setCheckAssets } = getContext;

  const [loading, setLoading] = useState(true);
  const [reportsData, setReportsData] = useState([]);
  const [show, setShow] = useState(false);
  const initialState = { reportId: "", type: "", recordName: "" };
  const [popupData, setpopupData] = useState(initialState);
  const [totalReportCount, setTotalReportCount] = useState(0);
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(1);

  const handleClose = () => setShow(false);

  const limit = 10;
  const getReport = async (page, limit) => {
    try {
      const response = await priceIntelligenceInstance().get(
        `/price/getAllreport`,
        {
          params: {
            page: page,
            limit: limit,
          },
        }
      );
      const { status, getReport } = response?.data;
      if (status === 200) {
        const { data, totalResult } = getReport || {};
        // console.log("data", data, totalResult);
    

        setTotalReportCount(totalResult);
        setReportsData([...reportsData, ...data]);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    let effectvar = true;
    if (effectvar) {
      getReport(page, limit);
    }
    return () => (effectvar = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  



 

  const handleClosePopup = () => {
    setpopupData(initialState);
    setShow(!show);
  };

  const handleClosePopupAfterSuccess = (reportId, msg) => {
    setShow(false);

    if (msg === "Record Deleted successfully!") {
      const updatedReportsData = reportsData.filter(
        (item) => item._id !== reportId
      );
      setReportsData(updatedReportsData);
    }
  };
  const handleOpenPopup = (data) => {
    //   setDataMsg(data.type);
    setpopupData(data);
    setShow(!show);
  };

  const fetchData = () => {
    // console.log("hello");
    // console.log("totalReportCount", totalReportCount);
    // console.log("reportsData?.length", reportsData?.length);
    if (reportsData?.length >= totalReportCount) {
      sethasMore(false);
    } else {
      setTimeout(() => {
        setPage(page + 1);
      }, 1000);
    }
  };

  const handleNavigationRoute = async (element) => {
    
    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    const price = calculateThreePercentLess(
      priceValue,
      element?.checkAssets || false
    );

    const jobNumber = element?.jobNumber;
    setPricestepData({ ...element?.priceFields, price, jobNumber });
    setCheckAssets(element?.checkAssets || false);
    const exportData = {
      ...element?.priceFields,
      price,
      jobNumber,
    };

    if (price && jobNumber && element?.priceFields) {
      handleClose();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/result", {
            state: {
              checkAsset: element?.checkAssets || false,
              id: element?._id,
              pricetepData: exportData,
              reportType: element?.giaReportNumber ? "byGia" : "manual",
            },
          });
          
      }, 3000);
      
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser().getResult())),
      ]);
      const { ip } = await ipResponse.json();
      const payload = {
        IP: ip,
        "Operating System": uaResult.os.name,
        Browser: uaResult.browser.name,
        Device: uaResult.device.model || "Unknown",
      };
      await priceIntelligenceInstance().post(
        `/price/sendHistoryWebhook/${element?.jobNumber}`,
        payload
      );
    } else {
      return;
    }
  };

  function calculateAdjustedPrice(element, checkAssets) {
    var priceValue = element.priceFields.price;

    if (element.giasaturation === "Fancy Deep") {
      priceValue = priceValue * 0.94;
    }

    return calculateThreePercentLess(priceValue, checkAssets) + " per ct";
  }
  const DiamondShapes = [
    { id: 1, img: "radient", value: "Radiant" },
    { id: 2, img: "cushion", value: "Cushion" },
    { id: 3, img: "oval", value: "Oval" },
    { id: 4, img: "heart", value: "Heart" },
    { id: 5, img: "pear", value: "Pear" },
    { id: 6, img: "emerald", value: "Emerald" },
    { id: 7, img: "round", value: "Round" },
    { id: 8, img: "marquise", value: "Marquise" },
    { id: 9, img: "hexagon", value: "Hexagon" },
    { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
    { id: 11, img: "octagon", value: "Octagon" },
    { id: 12, img: "princess", value: "Princess" },
    { id: 13, img: "trilliant", value: "Trilliant" },
    { id: 14, img: "kite", value: "Kite" },
    { id: 15, img: "shield", value: "Shield" },
  ];
  const getShapeForImage = (shape) => {
    const item = DiamondShapes.find((item) => shape === item.value);
    return item ? item.img : "";
  };

  const getTime = (time) => {
    let d = new Date(time);
    let pm = d.getHours() >= 12;
    let hour12 = d.getHours() % 12;
    if (!hour12) hour12 += 12;
    let minute = d.getMinutes();
    let date = d.getDate();
    let month = d.toLocaleString("en-US", { month: "short" });
    let year = d.getFullYear();
    return `${month}' ${dateSuffix(date)} ${year} ${hour12}:${
      minute < 10 ? "0" + minute : minute
    } ${pm ? "PM" : "AM"}`;
  };

  function dateSuffix(i) {
    var j = i % 10,
      k = i % 100;
      // eslint-disable-next-line
    if (j == 1 && k != 11) {
      return i + "st";
    }
    // eslint-disable-next-line
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    // eslint-disable-next-line
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
    {loading ? <div className="history-loader">
      <div className="diamond-animation-loader">
        <Lottie options={defaultOptions} />
      </div>
        
    </div> : 
    <div className="price-intelligence-layout">
      <div className={`start-pricing search-history  ${reportsData?.length === 0 ? "empty-history" : ""}`}>
        <div className="container history-container">
          <Header title="new-header" />
          <div>
            <h3>Price Intelligence History </h3>
          </div>
          <div className="d-flex flex-column gap-4">
            <InfiniteScroll
              dataLength={reportsData?.length}
              next={fetchData}
              hasMore={hasMore}
              loader={
                <div className="mt-5">
                  <Spinner animation="border" variant="warning" />
                </div>
              }
              style={{
                overflow: "hidden",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {reportsData?.length > 0 ? reportsData.map((item) => {
                return (
                  <div className="search-data">
                    <div className="search-data-box">
                      <div className="d-flex justify-content-between align-items-center gap-3">
                        <div
                          onClick={() =>
                            handleOpenPopup({
                              type: "delete",
                              reportId: item._id,
                            })
                          }
                        >
                          <img src={DelIcon} alt="delete" />
                        </div>
                        <div className="history-text" onClick={() => handleNavigationRoute(item)}>
                          <span
                            
                            role="presentation"
                            key={item?._id}
                          >
                            {/* #PI{item?.jobNumber}{" "}
                      {item?.giaReportNumber &&
                        `(${item?.giaReportNumber})`} */}
                      {/* <br /> */}
                            {item?.priceFields?.carat_weight}ct,{" "}
                            {item?.priceFields?.saturation}
                            {" - "}
                            {item?.checkAssets ? "Basic" : "Advanced"}  
                            <br />
                            {calculateAdjustedPrice(
                              item,
                              item?.checkAssets || false
                            )}
                          </span>
                          <div className="saved-card-date">
          Results reflect the data as of{" "}
          <span className="diamond-date-td">{getTime(new Date(item?.createdAt))}</span>
        </div>
                        </div>
                      </div>
                      <div className="saved-card-img">
                        <img
                          src={`${clientPath}/images/diamond-${item?.priceFields?.color?.toLowerCase()}-${getShapeForImage(
                            item?.priceFields?.shape
                          )}.svg`}
                          alt="diamond-icon"
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : "No data found"}
              <div className="divider"></div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <DeleteModal
        show={show}
        handleClosePopupAfterSuccess={handleClosePopupAfterSuccess}
        handleClosePopup={handleClosePopup}
        popupData={popupData}
      />
    </div>}
    <Footer />
     
    </>

    
  );
};

export default Searchhistory;

const DeleteModal = ({
  show,
  handleClosePopupAfterSuccess,
  handleClosePopup,
  popupData,
}) => {
  const [isActive, setisActive] = useState(false);

  const handleDelete = async () => {
    try {
        setisActive(true);

      const response = await priceIntelligenceInstance().delete(
        `price/deletePIReportById/${popupData.reportId}/history`
      );
      const {
        data: { status, msg },
      } = response || {};
      if (status === 200) {
        setisActive(false);
        // setSavedNotification(true);
        // setTimeout(() => {
        //   setSavedNotification(false);
        // }, 5000);

        handleClosePopupAfterSuccess(popupData.reportId, msg);
      }
    } catch (err) {
        setisActive(false);
      toast.error(err?.response?.data?.msg || "Internal server error", {
        toastId: "DeleteId",
      });
    }
  };
  return (
    <div>
      <Modal
        className="history-delete-modal"
        show={show}
        onHide={handleClosePopupAfterSuccess}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pb-3">
          <div class="history-delete-modal-body ">
            <p>Are you sure you want to delete?</p>
            <div class="delete-modal-btn">
              <button type="button" class="no-outline-btn btn btn-primary" onClick={handleDelete}>
                {isActive ? (
                  <Spinner animation="border" className="py-1" />
                ) : (
                  "Delete"
                )}
               
              </button>
              <button type="button" class="outline-btn btn btn-primary" onClick={handleClosePopup}>
                Cancel
              </button>
            </div>
          </div>
          
        </Modal.Body>
      </Modal>
    </div>
  );
};
