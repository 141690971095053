import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PriceIntelligenceLayout from "..";
import { Button, Spinner } from "react-bootstrap";
import arrow from "../../assets/images/pricing/arrow.svg";
import arrow1 from "../../assets/images/pricing/arrow.svg";
import bannerBg from "../../assets/images/pricing/banner_desktop.png";
import { priceIntelligenceInstance } from "../../config/axios";
import { PriceContext } from "../../context";
import { checkGIAReport, isShapeRound } from "../../utils/function";
import Home from "./home";
import { diamondPolish, diamondSymmetry } from "../../utils/diamondData";
import ErrorModal from "../ErrorModal/errorModal";
import menuicon from "../../assets/images/pricing/menuIcon.svg";
import searchHistory from "../../assets/images/pricing/search-icon.svg";
import creditIcon from "../../assets/images/pricing/credit.svg";
import DisclaimerModal from "../disclaimer/disclamerModal";
import Footer from "../footer/footer";
import { UAParser } from "ua-parser-js";
import * as animationData from "../diamondLoader/Diamondloader3d.json";
import DisableMobile from "../DisableMobile";
import Lottie from "react-lottie";

const StartPricing = () => {
  const getContext = useContext(PriceContext);
  const {
    initPayload,
    setPricestepData,
    setGiaReport,
    setIsManualPricing,
    setWidthValue,
    setLengthValue,
    setReportType,
    checkAssets,
    setCheckAssets,
    piCredits,
    setPICredits,
  } = getContext;
  const [loading, setLoading] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [reportnum, setReportnum] = useState("");
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [enableMobilePricing, setEnableMobilePricing] = useState(false);
  const [role, setRole] = useState("");


  const [showButton, setShowButton] = useState(true);
  const [GiaNum, setGiaNum] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [creditLoader, setCreditLoader] = useState(true);
  const location = useLocation();
  let navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    // console.log("loader", loader);
    GetPICreditsData();
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        // console.log("event.origin1", event.origin);
        // console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const { token } = event?.data;
          // console.log("token come form wp diary==>", token);
          // console.log("event?.data==>", event?.data);
          if (token) {
            localStorage.setItem("fcrf@token", token);
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@token")) {
            // console.log("window.location", window.location);
            // console.log("window.parent.location", window.parent.location);
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, location]);

  useEffect(() => {
    GetPICreditsData();
    setPricestepData(initPayload);
    setCheckAssets(
      localStorage.getItem("checkAssets") === "true" ? true : false
    );
    // eslint-disable-next-line
  }, [location]);

  const GetPICreditsData = async () => {
    try {
      setCreditLoader(true);
      const response = await priceIntelligenceInstance().get(
        `/price/get-pi-credits`
      );

      if (response?.data?.status === 200) {
        setPICredits(response?.data?.pi_credits);
        setDisclaimerModal(!response?.data?.pi_disclaimer_accepted);
        setRole(response?.data?.role);
        setCreditLoader(false);
      } else {
        setPICredits('-');
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleShowReport = async () => {
    if (reportnum.length < 7 || reportnum.length > 10) {
      setButtonDisable(true);
      return
    }

    try {
      setButtonDisable(true);
      setReportType("byGia");
      setLoading(true);
      // setLoader(true);

      if (reportnum !== "" || reportnum !== " ") {
        const Id = reportnum;

        const response = await priceIntelligenceInstance().get(
          `/price/getGIAReport/${Id}`
        );
        const { data, autoInputData, status, message } = response?.data;
        if (status !== 200) {
          setLoading(false);
          setLoader(false);
          setErrMsg(message);
          setErrorModal(true);
          return;
        }
        const { msg, code } = data || {};
        const { width, length } = autoInputData || {};
        if (code === 200) {
          setGiaReport(data);
          const isRoundShapeCheck = isShapeRound(autoInputData.shape);
          const polish = diamondPolish?.find(
            (s) => s.displayName === autoInputData.polish
          )?.code;
          const symmetry = diamondSymmetry?.find(
            (s) => s.displayName === autoInputData.symmetry
          )?.code;
          const newData = {
            giaReportNumber: reportnum,
            ratio: isRoundShapeCheck ? "1.00" : autoInputData?.ratio,
            length: isRoundShapeCheck ? 1 : autoInputData?.length,
            width: isRoundShapeCheck ? 1 : autoInputData?.width,
            polish,
            symmetry,
            singlePair:autoInputData?.giasaturation === "Fancy Deep" ? "" : "single",
            colorInnerGrade: autoInputData?.giasaturation === "Fancy Deep" ? "4":"",
          };
          const idu = {
            colorInnerGrade: autoInputData?.giasaturation === "Fancy Deep" ? "4":"2",
            colorDispersion: "3",
            colorUndertone: "3",
          };
          if (checkAssets) {
            setPricestepData({
              ...autoInputData,
              ...newData,
              ...idu,
              singlePair: "single",
            });
          } else {
            setPricestepData({ ...autoInputData, ...newData});
          }
          setLengthValue(length);
          setWidthValue(width);
          setShowButton(false);
          setIsManualPricing(false);
          setShowNextBtn(true);
          const checkPass = checkGIAReport(
            { ...autoInputData, ...newData },
            toast,
            setErrMsg
          );
          const formattedDate = new Date()
            .toLocaleString("he-IL", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: false,
              timeZone: "Asia/Jerusalem",
            })
            .replace(",", "");

          const ua = navigator.userAgent;
          const [ipResponse, uaResult] = await Promise.all([
            fetch("https://api.ipify.org?format=json"),
            new Promise((resolve) => resolve(new UAParser(ua).getResult())),
          ]);

          const { ip } = await ipResponse.json();
          const payload = {
            DateTime: formattedDate,
            Username: autoInputData?.username,
            Email: autoInputData?.email,
            ID: "#PI"+ autoInputData?.jobNumber,
            Tool: "Price Intelligence",
            Action: "Starting Price",
           "GIA Number": reportnum,
            "All User Information": autoInputData,
            services: {
              IP: ip,
              "Operating System": uaResult.os.name,
              Browser: uaResult.browser.name,
              Device: uaResult.device.model || "Unknown",
            },
          };
          if (checkPass?.status){
            await priceIntelligenceInstance().post(
              `price/send-pi-webhook`,
              payload
            );
          }

          

          if (!checkPass?.status) {
            setButtonDisable(false);
            setLoading(false);
            setErrorModal(true);
            const payload = {
              DateTime: formattedDate,
              Username: autoInputData?.username,
              Email: autoInputData?.email,
              ID: "#PI"+autoInputData?.jobNumber,
              Tool: "Price Intelligence",
              Action: "Search Rejected",
              Reason: checkPass?.msg,
              "GIA Number": reportnum,
              "All User Information": autoInputData,
              services: {
                IP: ip,
                "Operating System": uaResult.os.name,
                Browser: uaResult.browser.name,
                Device: uaResult.device.model || "Unknown",
              },
            };

            await priceIntelligenceInstance().post(
              `price/send-pi-webhook`,
              payload
            );
            // setLoader(false);
            return;
          }
          // setLoader(false);
          navigate(`${"/pricing"}`, {
            state: {
              action: "fromstartpricing",
              priceData: checkAssets
                ? { ...autoInputData, ...newData, ...idu, singlePair: "single" }
                : { ...autoInputData, ...newData },
            },
          });
        } else {
          setButtonDisable(false);
          setLoading(false);
          setErrMsg(msg);
          setErrorModal(true);
        }
      } else {
        setButtonDisable(false);
        setLoading(false);

        setGiaNum(false);
        setErrorModal(true);
        setErrMsg("Please enter GIA number");
      }
    } catch (e) {
      console.log("error", e);
      setButtonDisable(false);
      setLoading(false);
    }
  };

  const handleOnchange = async (val) => {
    setReportnum(val);
    if (val.length >= 7 && val.length <= 10) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  const routeChangeManual = () => {
    if (loader){
      return
    }
    setReportType("byManual");
    navigate(`${"/pricing"}`, {
      state: { action: "fromstartmanualpricing" },
    });
  };

  const handleGia = () => {
    if (loader){
      return
    }
    setGiaNum(true);
    setShowNextBtn(true);
  };

  const handleChecked = () => {
    setCheckAssets(!checkAssets);
    localStorage.setItem("checkAssets", !checkAssets);
  };

  const BackTohome = () => {
    setButtonDisable(true);
    setGiaNum(false);
    setReportnum("");
  };
  const [aboutOpen, setAboutOpen] = useState(false);

  const handleChangeAbout = () => {
    setAboutOpen(true);
  };

  const handleCloseAbout = () => {
    setAboutOpen(false);
  };

  // console.log("aboutOpen==>", aboutOpen);

  const [barMenuOpen, setBarMenuOpen] = useState();

  const handleMenubar = () => {
    setBarMenuOpen(!barMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setBarMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // console.log("role", role);
      const handleResize = () => {
        if (role === "contributor" || role === "administrator") {
          setEnableMobilePricing(false);

        }else{
          setEnableMobilePricing(window.innerWidth < 1225 ? true : false);
        }

      };

      // Add event listener to detect window resize
      window.addEventListener("resize", handleResize);

      // Cleanup: Remove event listener when component unmounts
      return () => window.removeEventListener("resize", handleResize);
      // eslint-disable-next-line
    }, [role]);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

  return  enableMobilePricing ? <DisableMobile /> : <PriceIntelligenceLayout>
  <div className="start-pricing">
    <div className="start-pricing-content start-pricing-desktop-content new-start-pricing">
      <div className="container">
        <div className="start-pricing-header">
          <div>
            <span>[Beta]</span>
            <h3>Price Intelligence</h3>
          </div>
          <div className="position-relative">
            <Button
              className="start-pricing-menuIcon"
              onClick={handleMenubar}
            >
              <img src={menuicon} alt="menu" />
            </Button>
            {barMenuOpen && (
              <div className="start-menu-pricing" ref={menuRef}>
                <div className="start-menu-pricing-data">
                  <Link
                    to="/price-intelligence-search-history"
                    className="start-menu-link"
                  >
                    <img src={searchHistory} alt="search-history" />{" "}
                    <p>Search History</p>
                  </Link>
                </div>
                <div className="start-menu-pricing-data">
                  <Link className="start-menu-link">
                  <img src={creditIcon} alt="search-history" /> <p> Credits: {creditLoader ? <> <Spinner animation="border" size="sm"  className="credit-spinner"/> </> : (piCredits ==='-1' || piCredits === -1)? 'Unlimited': piCredits}</p>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="start-pricing-container">
          <div className="start-pricing-banner">
            <img src={bannerBg} alt="bannerbg" />
          </div>
          <div className="pricing-boxes pricing-boxes-gia">
            <div>
              <div className="pricing-boxes-para">
                {/* <span>[Beta]</span> */}
                {GiaNum && !loading && (
                  <>
                    <div>
                      <span>[Beta]</span>
                      <p>Yellow, Pink and Blue 1 to 30 carat</p>
                    </div>
                  </>
                )}
                {!GiaNum && (
                  <p>
                    Layering GIA grades with visual assets to uncover
                    wholesale prices
                  </p>
                )}
                {loading && (
                  <div className="start-pricing-loader text-light">
                   <Lottie options={defaultOptions} />
                  </div>
                )}
              </div>
              <div>
                {!loading && (
                  <div className="start-pricing-form update-start-pricing-form w-100 bg-transparent">
                    <div className="start-pricing-formbox start-pricing-Updatedbox">
                      <div
                        className={`start-pricing-inpbox start-pricing-inpbox-new ${
                          !showButton ? "gia-number-input" : ""
                        }`}
                      >
                        {GiaNum && (
                          <>
                            <input
                              type="number"
                              placeholder="GIA Report Number"
                              value={reportnum}
                              onChange={(e) =>
                                handleOnchange(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleShowReport();
                                }
                              }}
                              autoFocus
                              className="w-100"
                            />
                          </>
                        )}
                      </div>

                      {showNextBtn && !loading && GiaNum && (
                        <div className="gia-summary-result gia_result_updated">
                          <div className="pricing-userlogin-content-btn ">
                            <Button
                              onClick={handleShowReport}
                              disabled={buttonDisable}
                            >
                              Next <img src={arrow} alt="arrow" />{" "}
                            </Button>
                          </div>
                          <div className="check_value_summary">
                            {checkAssets && (
                              <p className="visual-para">
                                Visual assets are not applied
                              </p>
                            )}
                            <Button
                              className="back_btn"
                              onClick={BackTohome}
                            >
                              {" "}
                              <img src={arrow1} alt="arrow" /> Back{" "}
                            </Button>
                          </div>
                        </div>
                      )}
                      {!GiaNum && (
                        <Home
                          loader={loader}
                                handleChecked={handleChecked}
                                checkAssets={checkAssets}
                                handleGia={handleGia}
                                routeChangeManual={routeChangeManual}
                        />
                      )}
                    </div>
                    {!GiaNum && (
                      <h4 className="confidential-mobile-text">
                        {" "}
                        Confidential{" "}
                      </h4>
                    )}
                    {/* <h4 className="confidential-desktop-text confidential-desktop-text-update"> Confidential </h4> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pricing-boxes about-price-boxes">
            <div className="">
              <div
                className={` ${
                  aboutOpen
                    ? "about-header"
                    : " d-flex justify-content-between"
                }`}
                onClick={handleChangeAbout}
                role="presentation"
              >
                <div className="para-about d-flex gap-2">
                  <div
                    className="text-center rotate-180 mob-arrow-dow"
                    role="presentation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="9"
                      viewBox="0 0 14 9"
                      fill="none"
                    >
                      <path
                        d="M8.03018 0.45579C7.48021 -0.156573 6.52059 -0.156572 5.97062 0.455791L0.443585 6.60993C-0.356657 7.50097 0.275725 8.91889 1.47336 8.91889L12.5274 8.91889C13.7251 8.91889 14.3575 7.50097 13.5572 6.60993L8.03018 0.45579Z"
                        fill="#0D1949"
                      />
                    </svg>
                  </div>
                  <p>About the price Intelligence</p>
                </div>
                {/* <div className="desk-arrow-down">
                    <img src={arrowDown} alt="arrowDown" />
                  </div> */}
              </div>
              <div
                className={`para-about  ${
                  aboutOpen ? "para-about-text" : "d-none"
                }`}
              >
                <p>
                  Prices may fluctuate over time with market trends and are
                  contingent upon the accuracy of your input; therefore,
                  they should not be considered guaranteed. While the price
                  range reflects values within the trade with high accuracy,
                  we recommend using it as a second opinion.
                </p>
                <div
                  className="text-center mt-3"
                  onClick={handleCloseAbout}
                  role="presentation"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                  >
                    <path
                      d="M8.03018 0.45579C7.48021 -0.156573 6.52059 -0.156572 5.97062 0.455791L0.443585 6.60993C-0.356657 7.50097 0.275725 8.91889 1.47336 8.91889L12.5274 8.91889C13.7251 8.91889 14.3575 7.50097 13.5572 6.60993L8.03018 0.45579Z"
                      fill="#0D1949"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <DisclaimerModal
    disclaimerModal={disclaimerModal}
    setDisclaimerModal={setDisclaimerModal}
  />
  <ErrorModal
    errorModal={errorModal}
    setErrorModal={setErrorModal}
    setLoading={setLoading}
    errMsg={errMsg}
    bg_remove_modal={"bg_remove_modal"}
  />
</PriceIntelligenceLayout>;
};

export default StartPricing;
