import { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import closeIcon from "../../assets/images/pricing/cross-icon.svg";
import { PriceIntelligence } from "../../utils/dataPI";
import InnerGradeComponent from "../pricing/InnerGrade";
import arrow from "../../assets/images/pricing/arrow.svg";
import DispersionComponent from "../pricing/Dispersion";
import UndertoneComponent from "../pricing/Undertone";
import { diamondDepthForManual } from "../../utils/diamondData";
import DesktopPairType from "../pricing/desktopPairType";
import InfoModal from "../infoModal/infoModal";
import infoIcon from "../../assets/images/pricing/info-icon.svg";
// import {InnerGradeComponent} from "../pricing/desktopAdvancePricing";

const AdjustIdu = ({
  show,
  setIduPopupOpen,
  pricestepData,
  setPricestepData,
  calculatePrice,
  reportType,
}) => {
  const [infoPopupData, setInfoPopupData] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const [step, setStep] = useState(2);
  function getValueByField(fieldName) {
    setInfoModal(true);
    let infoData = PriceIntelligence.find((item) => item.field === fieldName);
    setInfoPopupData(infoData);
  }
  // console.log("step", pricestepData);
  const handleNext = () => {
    if (step === 5) {
      setStep(2);
      calculatePrice();
    } else {
      setStep(step + 1);
    }
  };
  return (
    <>
      <div className="adjust-idu-container">
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Idu_popup_container Idu_desktop_outer adjust-idu-model"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className="col-12">
              <h3 className="mb-0 pb-0">Adjust IDU </h3>

              <span
                onClick={() => {
                  setIduPopupOpen(false);
                }}
              >
                <img src={closeIcon} alt="crossIcon" />
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {step === 2 && (
              <InnerGradeComponent
                step={step}
                setStep={setStep}
                pricestepData={pricestepData}
                setPricestepData={setPricestepData}
                getValueByField={getValueByField}
              />
            )}
            {step === 3 && (
              <DispersionComponent
                step={step}
                setStep={setStep}
                pricestepData={pricestepData}
                setPricestepData={setPricestepData}
                getValueByField={getValueByField}
              />
            )}
            {step === 4 && (
              <UndertoneComponent
                step={step}
                setStep={setStep}
                pricestepData={pricestepData}
                setPricestepData={setPricestepData}
                getValueByField={getValueByField}
              />
            )}

            {step === 5 && (
              <AdjustFaceup
                step={step}
                setStep={setStep}
                pricestepData={pricestepData}
                setPricestepData={setPricestepData}
                getValueByField={getValueByField}
                reportType={reportType}
              />
            )}

            {step !== 1 && (
              <div className="advance-idu-footer-section">
                <div className="pricing-userlogin-content-btn advance-pricing-btn m-0">
                  <Button
                    onClick={() => handleNext()}
                    disabled={
                      step === 2
                        ? !pricestepData?.colorInnerGrade
                        : step === 3
                        ? !pricestepData?.colorDispersion
                        : step === 4
                        ? !pricestepData?.colorUndertone
                        : false
                    }
                  >
                    <span>{step === 5 ? "Re- calculate" : "Next"}</span>
                  </Button>
                </div>
                <div
                  className={`back-button-section d-${
                    step === 2 ? "none" : "flex"
                  } align-items-center`}
                  role="button"
                  onClick={() => {
                    if (step === 2) {
                      return;
                    } else {
                      setStep(step - 1);
                    }
                  }}
                >
                  <Button className={`back-contain-btn `}>
                    <img src={arrow} alt="icon" />
                  </Button>
                  <p className={`p-0 m-0 back-text `}>Back</p>
                </div>
                <div className="idu-footer-section">
                  <p>
                    {step === 3
                      ? `Inner-grade ${
                          parseInt(pricestepData?.colorInnerGrade) === 5
                            ? "4+"
                            : pricestepData?.colorInnerGrade
                        }`
                      : step === 4
                      ? `Inner-grade ${
                          parseInt(pricestepData?.colorInnerGrade) === 5
                            ? "4+"
                            : pricestepData?.colorInnerGrade
                        }, Dispersion ${
                          parseInt(pricestepData?.colorDispersion) === 5
                            ? "4+"
                            : pricestepData?.colorDispersion
                        } `
                      : step === 5
                      ? `Inner-grade ${
                          parseInt(pricestepData?.colorInnerGrade) === 5
                            ? "4+"
                            : pricestepData?.colorInnerGrade
                        }, Dispersion ${
                          parseInt(pricestepData?.colorDispersion) === 5
                            ? "4+"
                            : pricestepData?.colorDispersion
                        }, Undertone ${
                          parseInt(pricestepData?.colorUndertone) === 5
                            ? "4+"
                            : pricestepData?.colorUndertone
                        } `
                      : ""}
                  </p>
                </div>
              </div>
            )}
            {infoModal && (
              <InfoModal
                infoModal={infoModal}
                setInfoModal={setInfoModal}
                heading={infoPopupData?.field}
                infoTxt={infoPopupData?.info}
                bg_remove_modal="bg_remove_modal adjust-idu-modal"
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AdjustIdu;

const AdjustFaceup = ({
  pricestepData,
  setPricestepData,
  getValueByField,
  reportType,
}) => {
  return (
    <div className="adjust-container">
      {reportType !== "byGia" && (
        <div className="adjust-faceup-container">
          <h5>
            {" "}
            <p className="d-flex m-0 align-items-center gap-1">
              <Button
                className="btn_info_icon"
                onClick={() => getValueByField("single/pair")}
              >
                <img
                  src={infoIcon}
                  alt="info_icon"
                  height="25px"
                  width="25px"
                />
              </Button>
            </p>
            true face-up
          </h5>
          <Form.Group
            className={`form-input-wrapper form-input-radiowrapper  flex-row gap-51 w-100`}
          >
            <div
              className={`shapes_container shapes-wd ${
                pricestepData?.shape !== "Round"
                  ? "shapes_face_mr"
                  : "shapes_face_mr_without_round"
              }`}
            >
              {diamondDepthForManual?.map((item, ind) => {
                return (
                  <div className="shape_radiobtn true_face_up_rc">
                    {pricestepData?.shape === "Round" && item?.Id === "8" ? (
                      <></>
                    ) : (
                      <p className="m-0">
                        {item.displayName?.includes("Face-Up - ")
                          ? item.displayName.split("Face-Up - ")[1]
                          : item.displayName}
                      </p>
                    )}
                    <input
                      type="radio"
                      id="diamond_face_up"
                      name="diamond_face-up"
                      hidden={
                        item?.Id === "8" && pricestepData?.shape === "Round"
                      }
                      // eslint-disable-next-line
                      checked={pricestepData?.trueFaceUp == item?.Id}
                      onChange={(e) => {
                        setPricestepData({
                          ...pricestepData,
                          trueFaceUp: parseInt(item?.Id),
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Form.Group>
        </div>
      )}

      <div className="adjust-pairType-container">
        <h5>
          {" "}
          <p className="d-flex m-0 align-items-center gap-1">
            <Button
              className="btn_info_icon"
              onClick={() => getValueByField("single/pair")}
            >
              <img src={infoIcon} alt="info_icon" height="25px" width="25px" />
            </Button>
          </p>
          single/pair{" "}
        </h5>
        <Form.Group
          className={`form-input-wrapper form-input-radiowrapper form-input-newtoggle iduToggle flex-row gap-51 w-100`}
        >
          <div className="shapes_container">
            <DesktopPairType
              name="singlePair"
              pricestepData={pricestepData}
              setPricestepData={setPricestepData}
            />
          </div>
        </Form.Group>
      </div>
    </div>
  );
};
