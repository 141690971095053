import { Button, Modal } from "react-bootstrap";
import "./infoModal.css";
import { useState } from "react";

const InfoModal = ({ infoModal, setInfoModal, heading, infoTxt,bg_remove_modal }) => {
  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    setClosing(true); // Apply closing animation
    setTimeout(() => {
      setInfoModal(false);
      setClosing(false); // Reset closing state after modal is hidden
    }, 300); // Match animation duration
  };
  return (
    <Modal
      show={infoModal}
      // onHide={() => {
      //   setInfoModal(false); // Set closing state
      //   setTimeout(() => setInfoModal(false), 3000); // Delay actual close
      // }}
      centered
      className={`${bg_remove_modal} info-modal back-modal ${closing ? "info-modal-closing" : ""}`}

    >
      <div className="close-btn">
      </div>
      <Modal.Body>
        <div className="back-modal-body">
          <h4>{heading}</h4>
          <div className="info-content">
            {infoTxt.map((text, i) => (
              <>
                <p key={i}>{text}</p>
              </>
            ))}
          </div>
          <div className="action-grid">
          <Button onClick={handleClose}>Close</Button>
          </div>
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
