// import { useEffect, useMemo, useState } from "react";
import { innerGrade } from "../../utils/piIduData";
import { clientPath } from "../../config/key";
import { useState } from "react";

const InnerGradeComponent = ({
  step,
  setStep,
  pricestepData,
  setPricestepData,
  getValueByField,
}) => {

  const [hoverIndex, setHoverIndex] = useState(null);
  if (step !== 2) return null;
  const { color, saturation, colorInnerGrade } = pricestepData;
  const currentGrade = innerGrade[color]?.[saturation] || [];


  return (
    <div>
      <div className="idu-section-container">
        <div className="idu-top-section">
          <h5>inner-grade</h5>
          <p>
            Defines the richness of color within the defined saturation.{" "}
            <span onClick={() => getValueByField("color Inner-Grade")}>
              Read more…
            </span>
          </p>
        </div>
        <div className="idu-main-section">
          <div className="idu-undertone-arrow-section"></div>
          <div className="idu-image-box">
            {currentGrade.map(({ image, bg }, index) => (
              <div className="idu-image-container">
                <div
                  key={index}
                  className={`idu-image ${
                    (colorInnerGrade ==="4+" ? "5" : colorInnerGrade) ===
                    (pricestepData?.saturation === "Fancy Deep"
                      ? "4"
                      : `${index + 1}`)
                      ? "active-image"
                      : ""
                  }`}
                  onClick={() =>
                    setPricestepData((prev) => ({
                      ...prev,
                      colorInnerGrade:
                        pricestepData?.saturation === "Fancy Deep"
                          ? "4"
                          : `${index + 1}`,
                    }))
                  }
                  onMouseEnter={() => {
                   setHoverIndex(index)
                  }}
                  onMouseLeave={() => {
                   setHoverIndex(null)
                  }}
                >
                  <img
                    src={`${clientPath}/pi-idu-images/${color.toLowerCase()}/${color.toLowerCase()}-${image}`}
                    alt={`Inner grade ${index + 1}`}
                  />
                </div>
                <p
                  style={{
                    backgroundColor: bg,
                    color: color === "Yellow" ? "#5C6DA4" : "#fff",
                  }}
                >
                  {pricestepData?.saturation === "Fancy Deep"
                    ? "4"
                    : index === 4
                    ? "4+"
                    : `${index + 1}`}
                </p>
              </div>
            ))}
          </div>

          {/* Description */}
          <div className="idu-text-box">
            <p>{currentGrade[hoverIndex ?? (colorInnerGrade - 1)]?.text}</p>
          </div>
        </div>

        {/* Footer */}
        <div className="idu-bottom-section">
          {Number(
            pricestepData?.colorInnerGrade === "4+"
              ? 5
              : pricestepData?.colorInnerGrade
          ) > 2 &&
            pricestepData?.saturation !== "Fancy Deep" && (
              <h6>
                {" "}
                <img
                  src={`${clientPath}/icons/idu_info.svg`}
                  height={"21px"}
                  width={"21px"}
                  alt="alert"
                />{" "}
                Overvaluing Visual Features Leads to Wrong Pricing!
              </h6>
            )}
        </div>
      </div>
    </div>
  );
};


export default InnerGradeComponent;