import {  useState } from "react";
import { dispersionTexts } from "../../utils/piIduData";
import { clientPath } from "../../config/key";
import { getOpacityValue } from "../../utils/function";

const DispersionComponent = ({
  pricestepData,
  setPricestepData,
  getValueByField,

}) => {
  const [dispersionHover, setDispersionHover] = useState(null);
  const [dispersionImages, ] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);
  const yellowDispersionColor = [
    "#E4C608",
    "#E4C608",
    "#E4C608",
    "#E4C608",
    "#E4C608",
  ];
  const pinkDispersionColor = [
    "#F4A2C8",
    "#DD81AB",
    "#C65C98",
    "#AC397A",
    "#A84C6E",
  ];
  const blueDispersionColor = [
    "#299AEC",
    "#057FD7",
    "#036EBA",
    "#005AA1",
    "#004E8B",
  ];

 
  return (
    <div>
                    <div className="idu-section-container">
                      <div className="idu-top-section">
                        <h5>dispersion</h5>
                        <p>
                          Measures the surface area of the defined yellow color
                          in the face-up view.{" "}
                          <span
                            onClick={() => getValueByField("color Dispersion")}
                          >
                            Read more…
                          </span>
                        </p>
                      </div>
                      <div className="idu-main-section">
                        <div className="idu-undertone-arrow-section"></div>
                        <div className="idu-image-box">
                          {dispersionImages?.map((item, index) => {
                            return (
                              <div className="idu-image-container">
                                <div
                                  className={`idu-image ${
                                    (pricestepData?.colorDispersion ==="4+" ? "5" : pricestepData?.colorDispersion) ===
                                    `${index + 1}`
                                      ? "active-image"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setPricestepData({
                                      ...pricestepData,
                                      colorDispersion: `${index + 1}`,
                                    });
                                  }}
                                  onMouseEnter={() => {
                                    // setDispersionImages((prevImages) =>
                                    //   prevImages.map((img, i) =>
                                    //     i === index ? `hover-${i + 1}` : img
                                    //   )
                                    // );
                                    setDispersionHover(index);
                                  }}
                                  onMouseLeave={() => {
                                    // setDispersionImages((prevImages) =>
                                    //   prevImages.map((img, i) =>
                                    //     i === index ? `${index + 1}` : img
                                    //   )
                                    // );
                                    setDispersionHover(null);
                                  }}
                                >
                                  <img
                                    src={`${clientPath}/pi-idu-images/${pricestepData?.color?.toLowerCase()}/${pricestepData?.color?.toLowerCase()}-dispersion-${item}.png`}
                                    alt=""
                                    style={{
                                      opacity: getOpacityValue(
                                        pricestepData?.saturation
                                      ),
                                      zIndex: dispersionHover !== index ? 1 : 0,
                                      // transition: "opacity 0.5s ease-in-out",
                                    }}
                                  />
                                  <img
                                    src={`${clientPath}/pi-idu-images/${pricestepData?.color?.toLowerCase()}/${pricestepData?.color?.toLowerCase()}-dispersion-hover-${item}.png`}
                                    i
                                    alt=""
                                    style={{
                                      opacity:
                                       (dispersionHover === index)? 1 :
                                       getOpacityValue(
                                        pricestepData?.saturation
                                      ),
                                      display:
                                        dispersionHover === index ? "block" : "none",
                                      zIndex: dispersionHover === index ? 1 : 0,
                                      // opacity:
                                      //   dispersionHover === index ? 1 : 0, // Control visibility
                                      // transition: "opacity .5s ease-in-out", // Slower transition
                                    }}
                                  />
                                </div>
                                <p
                                  style={{
                                    background:
                                      pricestepData?.color === "Yellow"
                                        ? yellowDispersionColor[index]
                                        : pricestepData?.color === "Pink"
                                        ? pinkDispersionColor[index]
                                        : blueDispersionColor[index],
                                    color:
                                      pricestepData?.color === "Yellow"
                                        ? "#5c6da4"
                                        : "#ffff",
                                  }}
                                >
                                  {index === 4 ? "4+" : `${index + 1}`}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        <div className="idu-text-box">
                          <p>
                            {
                              dispersionTexts[
                             dispersionHover ?? (pricestepData?.colorDispersion - 1) 
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div className="idu-bottom-section">
                        {Number(
                          pricestepData?.colorDispersion === "4+"
                            ? 5
                            : pricestepData?.colorDispersion
                        ) > 2 && (
                          <h6>
                            {" "}
                            <img
                              src={`${clientPath}/icons/idu_info.svg`}
                              height={"21px"}
                              width={"21px"}
                              alt="alert"
                            />{" "}
                            Overvaluing Visual Features Leads to Wrong Pricing!
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
  );
};

export default DispersionComponent;
